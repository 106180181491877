/* Switch */
$switchOff: #ccc;
$switchOn: #2196F3;

/* Checkbox */
$checkboxBackground: #ffffff;
$checkboxBorder: #000000;

/* Cpanel */
$cpBgGradient1: #251632;
$cpBgGradient2: #353139;
$cpPurple: #CFB1F1;
$cpPurpleLight: #BEB3CB;
$cpPurpleDark: #251632;
$cpTextDark: #251632;
$cpGrey: #D8D8D8;
$cpRed: #F8937B;
$cpYellow: #E5D580;

/* Admin */
$adminBgGradient1: #102440;
$adminBgGradient2: #313A47;
$adminBlue: #8DC4FF;
$adminBlueLight: #A5B9CC;
$adminBlueDark: #102440;
$adminTextDark: #102440;
$adminGrey: #D8D8D8;
$adminRed: #F8937B;

/* Facilitator */
$facilitatorBgGradient1: #182B32;
$facilitatorBgGradient2: #2F4046;
$facilitatorTextDark: #2F4046;
$facilitatorGreen: #80E589;
$facilitatorGreenLight: #AEC3B0;
$facilitatorBlue: #11466B;
$facilitatorBlueDark: #2F4046;
$facilitatorBlueLight: #8DC4FD;
$facilitatorRed: #F8937B;
$facilitatorRed2: #DD3426;
$facilitatorRed3: #FD6E6E;
$facilitatorRed4: #FA8372;
$facilitatorYellow: #E5D580;
$facilitatorGrey: #D8D8D8;


/* Landing page */
$blueDark: #2F4046;
$green: #AEC3B0;

/* Default login */
$loginBgGradient1: #182B32;
$loginBgGradient2: #2F4046;
$loginGreen: #80E589;
$loginGreenLight: #AEC3B0;
$loginTextDark: #2F4046;

$loginGrey: #D8D8D8;
$loginGreyDark: #999999;

/* Style 1 */
$style1Blue: #697690;
$style1BlueLight: #5670A4;
$style1BlueDark: #273262;
$style1BlueSky: rgb(164, 213, 228);
$style1Red: #DD3426;
$style1RedDark: #FB6453;
$style1Green: #8DB126;
$style1TextDark: #68778F;
$style1TextDark2: #222E5B;


// Order engine, item height
$style1OrderItemWidth: 22.84em;
$style1OrderItemHeight: 5em;
$style1OrderImageItemSize: 16.11em;
$style1orderItemWidthPortrait: 18em;
$style1OrderItemHeightPortrait: 4em;
$style1orderItemWidthPortraitWide: 25em;
$style1OrderImageItemSizePortrait: 10em;



// Mobile friendly layout
$mobileLandscapeWidth: 935px;
$mobilePortraitWidth: 500px;