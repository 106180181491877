@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Button.style-1 {
	height: 2.25em;
	@include style-1-button();

	&.blueLight {
		@include style-1-button-blue-light();
	}
	&.blue {
		@include style-1-button-blue();
	}
	&.green {
		@include style-1-button-green();
	}
	&.red {
		@include style-1-button-red();
	}
	&.next:not(.small),
	&.next-round,
	&.back-round,
	&.confirmTask {
		@include style-1-button-blue();
		height: 3em;
		span {
			font-size: 1.33em;
			font-weight: 700;
		}
		&.fadeIn {
			@include fadeInButton();
		}
		&.animate {
			@include opacity(0);
			animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
			-webkit-animation: fadeIn 0.25s ease 0.5s forwards, bounce 0.75s cubic-bezier(0.280, 0.840, 0.420, 1) 0.75s 1 forwards;
		}
	}
	&.next.small {
		width: 100%;
		height: 100%;
		@include style-1-button-blue();
		border-radius: 100%;
		span {
			display: none;
		}

		&::after {
			background-position: center right 1.2em;
		}
	}
	&.next {
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 3em;
			height: 100%;
			background-image: var(--icon-arrow-right);
			background-size: auto 1.5em;
			background-position: center right 1em;
			background-repeat: no-repeat;
		}
	}
	&.next-round {
		border-radius: 100%;
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 3em;
			height: 100%;
			background-image: var(--icon-arrow-right);
			background-size: auto 1.5em;
			background-position: center right 1em;
			background-repeat: no-repeat;
		}
	}
	&.back-round {
		border-radius: 100%;
		&::after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			width: 3em;
			height: 100%;
			background-image: var(--icon-arrow-right);
			background-size: auto 1.5em;
			background-position: center right 1em;
			background-repeat: no-repeat;
			@include scale(-1);
		}
	}
	&.result {
		span {
			font-size: 1.33em;
		}
	}
	&.text-blue {
		@include style-1-button-text();
		span {
			font-size: 1.33em;
			color: $style1Blue;
			text-decoration: underline;
		}
	}

	&.fullWidth {
		width: 100%;
	}
	&.fullHeight {
		height: 100%;
	}
	&.uppercase span {
		text-transform: uppercase;
	}
	&.bold span {
		font-weight: bold;
	}
}