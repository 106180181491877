@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Input.facilitator {
	border: 1px solid $facilitatorGrey;
	border-radius: 0.33em;
	padding: 0 1.11em;	

	&.file {
		padding: 0;
		border-radius: 1.33em;
		@include flex('center', 'center');
	}

	&.loading {
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/facilitator/icons/icon-loading.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}
	}

	&.green-light {
		text-align: center;
		background-color: $facilitatorGreenLight;
		label {
			
			font-size: 1em;
			font-weight: 700;
			color: $facilitatorTextDark;
		}
		&:hover {
			background-color: $facilitatorGreen;
		}
	}
}