@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.BookDemo {
	width: 100%;
	min-width: 100%;
	min-height: 100%;
	position: relative;
	background-image: linear-gradient(to bottom, $loginBgGradient1, $loginBgGradient2);
	background-size: 100% 45em;
	background-color: $green;
	background-position: center top;
	font-size: 14px;

	.BookDemo-header {
		padding-right: 2em;
		padding-left: 2em;
		padding-top: 2.2em;
		@include flex('flex-start', 'center', 'column');

		.BookDemo-subtitle {
			margin-top: 3em;
			width: 33.75em;
			color: white;
			@include flex('center');

			span {
				font-family: 'Metropolis', sans-serif;
				font-size: 3.33em;
				font-weight: bold;
			}
		}

		.BookDemo-language {
			position: absolute;
			right: 3.56em;
			top: 2.44em;
		}
	}

	.BookDemo-content {
		@include flex('', 'center', 'column');

		.BookDemo-greenArrow {
			position: absolute;
			background-image: url(../../assets/images/landing-page/green-arrow.svg);
			width: 7.2em;
			height: 7.7em;
			background-size: 100% 100%;
			left: calc(50% + 15em);
		}

		.BookDemo-phoneButton {
			span {
				font-size: 1em;
				font-weight: bold;
				color: $facilitatorGreen;
			}
		}

		.BookDemo-guideText {
			width: 20.4em;
			text-align: center;
			line-height: 1.3em;
			margin-top: 0.8em;

			span {
				font-family: 'Metropolis', sans-serif;
				color: white;
				font-size: 1.2em;
			}
		}

		.BookDemo-contentRow {
			margin-top: 4.1em;
			width: 100%;
			@include flex('center');

			.BookDemo-contentText {
				margin-right: 8.4em;
				margin-left: 4.5em;

				.BookDemo-contentTextHeader {
					color: white;
					margin-bottom: 2em;
					span {
						font-family: 'Metropolis', sans-serif;
						font-size: 1.6em;
						font-weight: bold;
					}
				}
				.BookDemo-contentTextPoints {
					margin-bottom: 2.3em;
					.BookDemo-contentPoint {
						@include flex('', 'center');
						margin-bottom: 1.85em;
						.BookDemo-pointImage {
							width: 2.8em;
							height: 2.8em;
							background-image: url(../../assets/images/icons/icon-checkmark.svg);
							background-size: 99% 99%;
							margin-right: 2.2em;
						}

						.BookDemo-pointText {
							width: 17.3em;
							color: white;
							line-height: 1.3em;

							span {
								font-family: 'Metropolis', sans-serif;
							}
						}
					}
				}
			}

			.BookDemo-inputWindow,
			.BookDemo-resultWindow {
				position: relative;
				width: 31.7em;
				height: 31.5em;
				background-color: white;
				border-radius: 1.11em;
				padding: 2.5em;
				margin-bottom: 2em;
				@include box-shadow(0, 0.17em, 0.33em, 0, rgba(#000000, 0.16));
				.BookDemo-inputWrapper {
					.BookDemo-inputHeader {
						margin-bottom: 0.6em;
						span {
							font-family: 'Metropolis', sans-serif;
							font-size: 0.9em;
							font-weight: 600;
						}
					}

					.BookDemo-input {
						margin-bottom: 1.4em;
						width: 100%;
						height: 2.7em;
					}
				}

				.BookDemo-bookButton {
					position: relative;
					left: calc(50% - 15.5em / 2);
					width: 15.5em;
					height: 3.33em;
				}
				.BookDemo-errMsg {
					position: absolute;
					bottom: 0.5em;
					span {
						font-size: 0.8em;
						color: $facilitatorRed;
					}
				}
			}
		}
	}
	
	/* Landscape */
	&.landscape-narrow,
	&.landscape {
		@media (width < 1000px) and (orientation: landscape) {
			.BookDemo-content {
				.BookDemo-contentRow {
					max-width: calc(100% - 2em);
					@include flex('space-between');
	
					.BookDemo-contentText {
						margin: 0;
					}
				}
			}
		}
	}

	/* Wide portrait (like tablets) */
	&.portrait-wide,
	/* Portrait */
	&.portrait {
		.BookDemo-header {
			padding-top: 4.5em;

			.BookDemo-subtitle {
				margin-top: 2em;
				max-width: calc(100% - 2em);

				span {
					font-size: 3em;
				}
			}
		}

		.BookDemo-content {
			position: relative;
			width: 100%;
			overflow-x: hidden;

			.BookDemo-greenArrow {
				width: 6.2em;
				height: 6.7em;
				top: calc(1em);
				left: calc(50% + 9.5em);
			}

			.BookDemo-contentRow {
				@include flex('', 'center', 'column-reverse');
				margin-top: 1.8em;

				.BookDemo-resultWindow,
				.BookDemo-inputWindow {
					max-width: calc(100% - 3.5em);
				}

				.BookDemo-contentText {
					@include flex('', 'center', 'column');
					margin: 0;

					.BookDemo-contentTextHeader {
						color: $facilitatorTextDark;
					}
					.BookDemo-contentTextPoints {
						.BookDemo-contentPoint {
							.BookDemo-pointImage {
								margin-right: 0.8em;
								background-image: url(../../assets/images/icons/icon-checkmark-dark.svg);
							}
							.BookDemo-pointText {
								color: $facilitatorTextDark;

								span {
									font-size: medium;
								}
							}
						}
					}
				}
			}
		}
	}
}