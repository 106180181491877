@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Input {
	position: relative;
	font-size: inherit;
	text-decoration: none;
	background-color: white;
	border: 1px solid black;
	border-radius: 0.25em;
	padding: 0 0.5em;
	@include flex('flex-start', 'center');

	&.readOnly {
		cursor: not-allowed;
	}

	&.button {
		cursor: pointer;
		@include flex('center', 'center');
	}

	&.file {
		label {
			@include flex('center', 'center');
			width: 100%;
			height: 100%;
			cursor: pointer;
			@include no-select();
		}
		input {
			display: none;
		}
	}

	&.number {
		text-align: center;
	}

	&.fullWidth {
		width: 100%;
	}
	&.fullHeight {
		height: 100%;
	}
	&.alignRight {
		text-align: right;
	}

	&.selectResponseGreen {
		&:focus {
			border-color: $loginGreen;
			border-width: 0.11em;
		}
	}
 
	&.login {
		font-size: 0.88em; // 16px
		height: 3em;
		border-radius: 0.375em;
		border: 1px solid $loginGrey;
		padding-left: 1em;
		color: $loginTextDark;
	}
}