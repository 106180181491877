/* Widget */
@mixin widget-button-wrap() {
	position: absolute;
	bottom: 1.33em;
	left: 1.33em;
	width: calc(100% - 2 * 1.33em);
	height: 2em;
	.Button {
		span {
			font-size: 0.89em;
			font-weight: 600;
		}
	}
}

/* Style 1 */
@mixin style-1-button() {
	border-radius: 0.33em;
	@include box-shadow-inset-inset-outset(
		0, 0.25em, 0, 0, rgba(white, 0.3),
		0, -0.28em, 0, 0, rgba(black, 0.2),
		0, 0.17em, 0.33em, 0, rgba(black, 0.2)
	);
	@include flex('center', 'center');
	@include no-select();
}
@mixin style-1-button-text() {
	border-radius: 0;
	@include no-box-shadow();
}
@mixin style-1-button-blue-light() {
	@include style-1-button();
	background-image: linear-gradient(#7395C5, $style1BlueLight);
	background-color: $style1BlueLight;
	p,
	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
		background-image: linear-gradient(darken(#7395C5, 3%), darken($style1BlueLight, 3%));
		background-color: darken($style1BlueLight, 3%);
	}
}
@mixin style-1-button-blue() {
  @include style-1-button();
	background-image: linear-gradient(#4355A7, $style1BlueDark);
	background-color: $style1BlueDark;
	p,
	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
    background-image: linear-gradient(darken(#4355A7, 3%), darken($style1BlueDark, 3%));
		background-color: darken($style1BlueDark, 3%);
	}
}
@mixin style-1-button-green() {
	@include style-1-button();
	background-color: $style1Green;
	background-image: linear-gradient(#ACD42F, $style1Green);
	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
    background-image: linear-gradient(darken(#ACD42F, 3%), darken($style1Green, 3%));
		background-color: darken($style1Green, 3%);
	}
}
@mixin style-1-button-red() {
	@include style-1-button();
	background-color: $style1Red;
	background-image: linear-gradient(#FB6453, $style1Red);
	span {
		color: white;
	}
	&:hover,
	&:focus,
	&:active {
    background-image: linear-gradient(darken(#FB6453, 3%), darken($style1Red, 3%));
		background-color: darken($style1Red, 3%);
	}
}