@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Button.facilitator {
	border-radius: 1.33em;

	&.rounded {
		border-radius: 3.33em;
	}

	&.loading {
		&::after {
			background-image: url('../../../assets/images/facilitator/icons/icon-loading.svg');
		}
	}

	&.info {
		width: 2.22em;
		height: 2.22em;
		border-radius: 100%;
		background-color: $facilitatorGreenLight;
		background-image: url('../../../assets/images/facilitator/icons/icon-info.svg');
		background-size: auto 1em;
	}

	&.green {
		background-color: $facilitatorGreen;
		span {
			font-size: 1em;
			font-weight: 700;
			color: $facilitatorTextDark;
		}
		&:hover {
			background-color: darken($facilitatorGreen, 5%);
		}
	}

	&.red {
		background-color: $facilitatorRed2;
		span {
			font-size: 1em;
			font-weight: 700;
			color: $facilitatorTextDark;
		}
		&:hover {
			background-color: darken($facilitatorRed2, 5%);
		}
	}

	&.red-trans {
		background-color: transparent;
		border: 0.11em solid $facilitatorRed;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $facilitatorTextDark;
		}
		&:hover {
			background-color: rgba($facilitatorRed, 0.1);
		}
	}

	&.responseGreen {
		&:hover,
		&:focus,
		&:active {
			background-color: $facilitatorGreen;
			background-image: linear-gradient($facilitatorGreen, $facilitatorGreen);
			span {
				color: $facilitatorTextDark;
			}
		}
	}
	&.responseOutlineGreen {
		&:hover,
		&:focus,
		&:active {
			background-color: transparent !important;
			background-image: linear-gradient(transparent, transparent);
			border: 0.11em solid $facilitatorGreen;
		}
	}
	&.responseOutlineRed {
		&:hover,
		&:focus,
		&:active {
			background-color: transparent !important;
			background-image: linear-gradient(transparent, transparent);
			border: 0.11em solid $facilitatorRed2;
		}
	}

	&.green-trans {
		background-color: transparent;
		border: 0.11em solid $facilitatorGreen;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $facilitatorTextDark;
		}
		&:hover {
			background-color: rgba($facilitatorGreen, 0.1);
		}
	}

	&.green-light {
		background-color: $facilitatorGreenLight;
		span {
			font-size: 1em;
			font-weight: 700;
			color: $facilitatorTextDark;
		}
		&:hover {
			background-color: $facilitatorGreen;
		}
	}
	&.green-light-trans {
		border-radius: 0.83em;
		background-color: transparent;
		border: 0.11em solid $facilitatorGreenLight;
		padding-left: 0.5em;
		padding-right: 0.5em;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $facilitatorGreenLight;
		}
		&:hover {
			background-color: rgba($facilitatorGreenLight, 0.1);
		}
	}
	
	&.blue {
		background-color: $facilitatorBlue;
		&:hover {
			background-color: darken($facilitatorBlue, 3%);
		}
		span {
			color: white;
			font-weight: bold;
		}
	}
	&.blue-trans {
		border-radius: 0.83em;
		background-color: transparent;
		border: 0.11em solid $facilitatorBlueDark;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $facilitatorBlueDark;
		}
		&:hover {
			background-color: rgba($facilitatorBlueDark, 0.1);
		}
	}

	&.normalTextSize span {
		font-size: 1em; // 18px 
	}
	&.smallText span {
		font-size: 0.78em; // 14px
	}

	&.animateCheck {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/facilitator/icons/icon-checkmark-white.svg');
			background-size: auto 50%;
			background-position: right 1em center;
			@include fadeOut(0.5s, 2s);
		}
	}

	&.back {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			background-image: url('../../../assets/images/facilitator/icons/icon-arrow-left.svg');
			background-size: auto 50%;
			background-position: left 1em center;
		}
	}
}