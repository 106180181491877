@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Select.admin {
	width: 100%;
	height: 100%;
	background-color: white;
	color: $adminTextDark;
	border-radius: 1.11em;
	.Select-panel {
		height: 100%;
		border: 1px solid $adminBlueLight;
		border-radius: 1.11em;
	}

	.Select-button {
		width: 2.5em;
		background-image: url('../../../assets/images/admin/icons/icon-arrow-down.svg');
	}

	.Select-dropdown {
		top: calc(2.22em - 2px);
		.Select-options {
			max-height: 10em;
			@include fancy-scrollbar(0.5em,1em,rgba($adminBlueLight, 0.5),0.5em,0.5em, 1em, $adminBlueLight);
			.Select-option {
				height: 2.22em;
				&:hover {
					background-color: rgba($adminBlueLight, 0.2);
				}
				&.selected {
					background-color: rgba($adminBlueLight, 0.3);	
				}
				
			}
		}
	}

	&.open {
		.Select-panel {
			border-radius: 1.11em 1.11em 0 0;
		}
		.Select-dropdown {
			padding: 0.2em;
			border-radius: 0 0 1.11em 1.11em;
			border: 1px solid $adminBlueLight
		}
	}
}