@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Button {
	display: inline-block;
	position: relative;
	font-size: inherit;
	text-align: center;
	text-decoration: none;
	cursor: pointer;
	@include no-select();
	@include opacity(1);
	@include flex('center', 'center');
	span {
		display: inline-block;
		line-height: 1;
	}
	&.disabled {
		@include opacity(0.4);
		cursor: not-allowed;		
		&:hover,
		&:focus,
		&:active {
			@include opacity(0.4);
		}
	}
	&.loading {
		&::after {
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			pointer-events: none;
			background-image: url('../../../assets/images/icons/icon-loading-white.svg');
			background-size: auto 80%;
			background-position: right 1em center;
		}
	}
	&.logout {
		width: 2.5em;
		height: auto;
		padding-top: 2em;
		border-radius: 0;
		background-image: url('../../../assets/images/icons/icon-logout.svg');
		background-size: 2em auto;
		background-position: top right;
		@include flex('flex-end', 'center');
		span {
			color: white;
			font-size: 0.6em;
		}
	}

	&.midSized {
		width: 8em;
		height: 2.5em;
	}

	&.responseWhite {
		&:hover,
		&:focus,
		&:active {
			background-color: white;
			background-image: linear-gradient(white, white);
		}
	}
	&.fullWidth {
		width: 100%;
	}
	&.fullHeight {
		height: 100%;
	}
	&.uppercase span {
		text-transform: uppercase;
	}
	&.bold span {
		font-weight: bold;
	}
}