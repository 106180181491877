@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/animations';
@import '../styles/buttons';
.App {
	position: relative;
	height: 100%;
	min-height: 100%;
	.App-preloadedImages {
		position: absolute;
		width: 0;
		height: 0;
		z-index: -1000;
		overflow: hidden;
	}
}