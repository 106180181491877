@import 'styles/fonts';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/animations';
@import 'styles/buttons';
html {
  position: relative;
  // font-size: calc(18 * ((100vw / 375)));
  font-size: calc(18 * ((100vw / 1920))); // tablet first layout
  height: 100%;
}

body {
  position: relative;
	height: 100%;
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Metropolis', sans-serif;
  font-size: 1em;
  font-weight: 500;
}

#root {
  height: 100%;
  min-height: 100%;
}

*,
*::after,
*::before {
	box-sizing: border-box;
  font-family: 'Metropolis', sans-serif;
	font-size: 1em;
  background-size: contain;
	background-repeat: no-repeat;
  background-position: center center;
}

button {
	font-size: inherit;
  background-image: none;
  border-style: none;
  -webkit-tap-highlight-color: transparent;
  @include no-select();
  &:focus {
    outline: 0;
  }
  &::-moz-focus-inner {
    border: 0;
  }
}

ul, ol {
	font-size: 1em;
	padding-inline-start: 1.5em;
	margin: 0;
}

a {
  color: inherit;
}

input,
textarea {
	font-size: 1em;
  color: inherit;
  font-size: inherit;
  outline: none;
  box-shadow: none;
  @include appearance(none);
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
  
}