@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Button.admin {
	border-radius: 1.33em;

	&.rounded {
		border-radius: 3.33em;
	}

	&.loading {
		&::after {
			background-image: url('../../../assets/images/admin/icons/icon-loading.svg');
		}
	}

	&.blue {
		background-color: $adminBlue;
		span {
			font-size: 1em;
			font-weight: 700;
			color: $adminTextDark;
		}
		&:hover {
			background-color: darken($adminBlue, 5%);
		}
	}

	&.blue-trans {
		background-color: transparent;
		border: 0.11em solid $adminBlue;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $adminTextDark;
		}
		&:hover {
			background-color: rgba($adminBlue, 0.1);
		}
	}

	&.blue-light {
		background-color: $adminBlueLight;
		span {
			font-size: 1em;
			font-weight: 700;
			color: $adminTextDark;
		}
		&:hover {
			background-color: $adminBlue;
		}
	}
	&.blue-light-trans {
		border-radius: 0.83em;
		background-color: transparent;
		border: 0.11em solid $adminBlueLight;
		padding-left: 0.5em;
		padding-right: 0.5em;
		span {
			font-size: 0.78em;
			font-weight: 600;
			color: $adminBlueLight;
		}
		&:hover {
			background-color: rgba($adminBlueLight, 0.1);
		}
	}
	
	&.normalTextSize span {
		font-size: 1em; // 18px 
	}
	&.smallText span {
		font-size: 0.78em; // 14px
	}
}