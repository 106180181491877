@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.Select.facilitator,
.Select {
	width: 100%;
	height: 100%;
	background-color: white;
	color: $facilitatorTextDark;
	border-radius: 1.11em;
	.Select-panel {
		height: 100%;
		border: 1px solid $facilitatorGreenLight;
		border-radius: 1.11em;
	}

	.Select-button {
		width: 2.5em;
		background-image: url('../../../assets/images/facilitator/icons/icon-arrow-down.svg');
	}

	.Select-dropdown {
		top: calc(2.22em - 2px);
		padding-right: 1em;
		.Select-options {
			max-height: 10em;
			@include fancy-scrollbar(0.5em,1em,rgba($facilitatorGreenLight, 0.5),0.5em,0.5em, 1em, $facilitatorGreenLight);
			.Select-option {
				height: auto;
				margin-bottom: 0.5em;
				&:hover {
					background-color: rgba($facilitatorGreenLight, 0.2);
				}
				&.selected {
					background-color: rgba($facilitatorGreenLight, 0.3);
				}
			}
		}
	}

	&.open {
		.Select-panel {
			border-radius: 0.8em 0.8em 0 0;
		}
		.Select-dropdown {
			padding: 0.2em;
			border-radius: 0 0 0.8em 0.8em;
			border: 1px solid $facilitatorGreenLight;
		}
	}

	&.green-light-trans {
		background-color: transparent;
		color: $facilitatorGreenLight;
		.Select-button {
			background-image: url('../../../assets/images/facilitator/icons/icon-arrow-down-green-light.svg');
		}
		.Select-dropdown {
			.Select-options {
				.Select-option {
					p {
						color: $facilitatorTextDark;
					}
				}
			}
		}
		&.open {
			.Select-panel {
				background-color: $facilitatorGreenLight;
				color: $facilitatorTextDark;
				.Select-button {
					background-image: url('../../../assets/images/facilitator/icons/icon-arrow-up.svg');
				}
			}
		}
	}

	&.header {
		.Select-panel {
			.Select-selected {
				padding-left: 1em;
				font-size: 0.78em;
				font-weight: 600;
			}
		}
		.Select-dropdown {
			top: calc( 1.67em + 0.25em);
			padding-right: 0.5em;
			.Select-options {
				.Select-option {
					margin-bottom: 0.5em;
					border-radius: 0.2em;
					p {
						font-size: 0.78em;
					}
				}
			}
		}
		&.departments {
			.Select-dropdown {
				.Select-options {
					max-height: 30em;
					.Select-option {
						p {
							font-weight: 700;
							span {
								font-size: 0.75em;
								font-weight: normal;
							}
						}
					}
				}
			}
		}
		&.open {
			.Select-panel {
				border-radius: 1.11em;
			}
			.Select-dropdown {
				border-radius: 1.11em;
			}
		}
	}
}