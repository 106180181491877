@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';
.PlatformTitle {
	position: relative;
	height: 3em;
	@include flex('', 'flex-end');
	.PlatformTitle-trainingGamesLogo {
		background-image: url('../../../assets/images/logos/logo-platform-green.svg');
		width: 3.7em;
		height: 2.9em;
		margin-right: 0.94em;
	}

	span {
		font-family: 'Metropolis', sans-serif;
		color: $loginGreen;
		font-size: 2.22em;
		font-weight: 800;
	}

	&.cpanel {
		display: inline-block;
		@include flex('center', 'flex-end');
		.PlatformTitle-trainingGamesLogo {
			background-image: url('../../../assets/images/logos/logo-platform-purple.svg');
		}
		span {
			color: $cpPurple;
		}
	}
}