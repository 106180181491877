@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import '../../../styles/buttons';

.CGLLogo {
	position: absolute;
	background-image: url(../../../assets/images/logos/logo-cgl-green.svg);
	top: 2em;
	left: 2.2em;
	width: 9em;
	height: 1.8em;
	&:hover {
		cursor: pointer;
		background-image: url(../../../assets/images/logos/logo-cgl-white.svg);
	}
}