/* METROPOLIS */
@font-face {
  font-family: 'Metropolis';
  src: url('../assets/fonts/metropolis-medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('../assets/fonts/metropolis-semi-bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('../assets/fonts/metropolis-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Metropolis';
  src: url('../assets/fonts/metropolis-extra-bold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}