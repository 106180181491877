// APPEARANCE
@mixin appearance($value) {
	-webkit-appearance: #{$value};
	-moz-appearance:    #{$value};
	appearance:         #{$value};
}

// PLACEHOLDER
@mixin placeholderColor($color) {
	&::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: $color;
		opacity: 1; /* Firefox */
	}
	&:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: $color;
	}
	&::-ms-input-placeholder { /* Microsoft Edge */
		color: $color;
	}
}

// TOUCH ACTION
@mixin touchaction($value) {
	touch-action: #{$value};
	-ms-touch-action: #{$value};
}

// FLEX
@mixin flex($justify:"", $align:"", $direction:"") {
	display: -webkit-box;      // OLD - iOS 6-, Safari 3.1-6
	display: -moz-box;         // OLD - Firefox 19- (buggy but mostly works)
	display: -ms-flexbox;      // TWEENER - IE 10
	display: -webkit-flex;     // NEW - Chrome
	display: flex;             // NEW, Spec - Opera 12.1, Firefox 20+
	@include flex-properties($justify, $align, $direction);
}
@mixin inline-flex($justify:"", $align:"", $direction:"") {
	display: -webkit-inline-box;
	display: -moz-inline-box;
	display: -ms-inline-flexbox;
	display: -webkit-inline-flex;
	display: inline-flex;
  @include flex-properties($justify, $align, $direction);
}
@mixin flex-properties($justify, $align, $direction) {
	@if $justify !="" {
		-webkit-justify-content: #{$justify};
		justify-content: #{$justify};
		-webkit-box-pack: #{$justify};
		-ms-flex-pack: #{$justify};
	}
	@if $align !="" {
		-webkit-box-align: #{$align};
		-webkit-flex-align: #{$align};
		-ms-flex-align: #{$align};
		-webkit-align-items: #{$align};
		align-items: #{$align};	
	}
	@if $direction !="" {
		-webkit-box-direction: #{$direction};
		-webkit-flex-direction: #{$direction};
		flex-direction: #{$direction};
	}
}
@mixin flex-wrap($wrap) {
	-webkit-flex-wrap: $wrap; /* Safari 6.1+ */
	flex-wrap: $wrap;
}
@mixin align-content($align) {
	-webkit-align-content: $align;
    align-content: $align;
}
@mixin align-self($value) {
	-webkit-align-self: #{$value};
	align-self: #{$value};
}

// ORIGIN
@mixin origin($x, $y) {
	-webkit-transform-origin: $x $y;
	transform-origin: $x $y;
}

// ROTATE
@mixin rotate($angle) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle);
}

// SCALE
@mixin scale($scale) {
	-ms-transform: scale($scale); /* IE 9 */
	-webkit-transform: scale($scale); /* Safari */
	transform: scale($scale);
}

@mixin scaleX($scale) {
	-ms-transform: scaleX($scale); /* IE 9 */
	-webkit-transform: scaleX($scale); /* Safari */
	transform: scaleX($scale);
}

@mixin scaleY($scale) {
	-ms-transform: scaleY($scale); /* IE 9 */
	-webkit-transform: scaleY($scale); /* Safari */
	transform: scaleY($scale);
}

// ROTATE AND SCALE
@mixin rotateAndscale($angle, $amount) {
	-ms-transform: rotate($angle); /* IE 9 */
	-webkit-transform: rotate($angle); /* Safari */
	transform: rotate($angle) scale($amount);
}

// TRANSITION
@mixin transition($seconds: '1s', $target: 'all', $type: 'linear') {
	-webkit-transition: #{$target} $seconds #{$type}; /* WebKit */
	-moz-transition: #{$target} $seconds #{$type}; /* Firefox */
	-o-transition: #{$target} $seconds #{$type}; /* Opera */
	-ms-transition: #{$target} $seconds #{$type}; /* MS */
	transition: #{$target} $seconds #{$type}; /* Standard */
}

// 2 TRANSITIONS
@mixin transitionMultiple($target1: 'all', $target2: 'all', $seconds: 0.5, $type: 'ease') {	
	-moz-transition: #{$target1} $seconds*1s #{$type}, #{$target2} $seconds*1s #{$type};		/* Firefox */
	-webkit-transition: #{$target1} $seconds*1s #{$type}, #{$target2} $seconds*1s #{$type};	/* WebKit */
	-o-transition: #{$target1} $seconds*1s #{$type}, #{$target2} $seconds*1s #{$type};			/* Opera */
	transition: #{$target1} $seconds*1s #{$type}, #{$target2} $seconds*1s #{$type};					/* Standard */
}

// TRANSITION TRANSFORMS
@mixin transitionTransform($seconds, $type, $delay: 0) {
	-webkit-transition: -webkit-transform $seconds #{$type} $delay; /* WebKit */
	-moz-transition: -moz-transform $seconds #{$type} $delay; /* Firefox */
	-o-transition: -o-transform $seconds #{$type} $delay; /* Opera */
	-ms-transition: -ms-transform $seconds #{$type} $delay; /* MS */
	transition: transform $seconds #{$type} $delay; /* Standard */
}

/* TRANSLATE 3D */
@mixin translate3d($x, $y: 0, $z: 0) {
	-ms-transform: translate3d($x, $y, $z);
	-webkit-transform: translate3d($x, $y, $z);
	transform: translate3d($x, $y, $z);
}

/* TRANSLATE */
@mixin translate($x, $y: 0) {
	-webkit-transform: translate($x, $y);
	-moz-transform: translate($x, $y);
	-ms-transform: translate($x, $y);
	-o-transform: translate($x, $y);
	transform: translate($x, $y);
}

@mixin translateX($x) {
	-webkit-transform: translateX($x);
	-moz-transform: translateX($x);
	-ms-transform: translateX($x);
	-o-transform: translateX($x);
	transform: translateX($x);
}

/* TRANSLATE AND ROTATE */
@mixin translateAndRotate($x, $y, $z, $angle) {
	-ms-transform: translate3d($x, $y, $z) rotate($angle);
	-webkit-transform: translate3d($x, $y, $z) rotate($angle);
	transform: translate3d($x, $y, $z) rotate($angle);
}

/* TRANSLATE AND SCALE */
@mixin translateAndScale($x, $y: 0, $amount) {
	-ms-transform: translate($x, $y) scale($amount);
	-webkit-transform: translate($x, $y) scale($amount);
	transform: translate($x, $y) scale($amount);
}

// ANIMATION 
@mixin animation($name, $duration, $delay: 0, $iteration-count: 1, $direction: 'normal', $timing-function: 'ease', $fill-mode: 'none') {
	-webkit-animation: #{$name} $duration*1s $delay*1s $iteration-count #{$direction} #{$timing-function} #{$fill-mode};
	-moz-animation: #{$name} $duration*1s $delay*1s $iteration-count #{$direction} #{$timing-function} #{$fill-mode};
	-o-animation: #{$name} $duration*1s $delay*1s $iteration-count #{$direction} #{$timing-function} #{$fill-mode};
	animation: #{$name} $duration*1s $delay*1s $iteration-count #{$direction} #{$timing-function} #{$fill-mode};
};

// NO ANIMATION 
@mixin no-animation() {
	-webkit-animation: none !important;
	-moz-animation: none !important;
	-o-animation: none !important;
	-ms-animation: none !important;
	animation: none !important;
}

// OPACITY
@mixin opacity($opacity) {
	$procentOpacity: $opacity * 100;	
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="$procentOpacity")"; /* IE 8 */
	filter: alpha(opacity=$procentOpacity); /* IE 5-7 */
	-moz-opacity: $opacity; /* Netscape */
	-khtml-opacity: $opacity; /* Safari 1.x */
	opacity: $opacity;
}

// BLUR
@mixin blur($value) {
	-webkit-filter: blur($value); /* Ch 23+, Saf 6.0+, BB 10.0+ */
	filter: blur($value); /* FF 35+ */
}

// INPUT
@mixin input-placeholder($color) {
	input::-webkit-input-placeholder {color: $color !important;}	/* Chrome/Opera/Safari */
	input:-moz-placeholder {color: $color !important;}  					/* Firefox 18- */
	input::-moz-placeholder {color: $color !important;} 					/* Firefox 19+ */
	input:-ms-input-placeholder {color: $color !important;}			/* IE 10+ */
}

// TEXTAREA
@mixin textarea-placeholder($color) {
	textarea::-webkit-input-placeholder {color: $color !important;}	/* Chrome/Opera/Safari */
	textarea:-moz-placeholder {color: $color !important;}  					/* Firefox 18- */
	textarea::-moz-placeholder {color: $color !important;} 					/* Firefox 19+ */
	textarea:-ms-input-placeholder {color: $color !important;}			/* IE 10+ */
}

// NO-SELECT
@mixin no-select() {
	-webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently*/
}

// HIDE SCROLLBAR
@mixin hide-scrollbar() {
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

// FANCY SCROLLBAR
/* Chrome/Safari/Opera 15+/Android/iOS (NOT Firefox or IE) */
@mixin fancy-scrollbar($width,$height,$backgroundColor,$borderRadius,$thumbWidth, $thumbHeight, $thumbColor) { 
	&::-webkit-scrollbar {
		width: $width;
		height: $height;
	}
	
	&::-webkit-scrollbar-track {
		background-color: $backgroundColor;
		border-radius: $borderRadius;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: $borderRadius;
		background: $thumbColor;
		width: $thumbWidth;
		height: $thumbHeight;
	}
	&::-webkit-scrollbar-thumb:window-inactive {
		//background-color: transparent;
		border: 1px solid $thumbColor;
	}
}

// NO BOX SHADOW
@mixin no-box-shadow() {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}

// BOX SHADOW
@mixin box-shadow($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: $hoffset $voffset $blur $spread $color;
	box-shadow: $hoffset $voffset $blur $spread $color;
}

// BOX SHADOW INSET
@mixin box-shadow-inset($hoffset, $voffset, $blur, $spread, $color) {
	-webkit-box-shadow: inset $hoffset $voffset $blur $spread $color;
	-moz-box-shadow: inset $hoffset $voffset $blur $spread $color;
	box-shadow: inset $hoffset $voffset $blur $spread $color;
}

@mixin box-shadow-inset-inset-outset(
	$hoffset1, $voffset1, $blur1, $spread1, $color1,
	$hoffset2, $voffset2, $blur2, $spread2, $color2,
	$hoffset3, $voffset3, $blur3, $spread3, $color3,
) {
	-webkit-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2 inset,
		$hoffset3 $voffset3 $blur3 $spread3 $color3;
	-moz-box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2 inset,
		$hoffset3 $voffset3 $blur3 $spread3 $color3;
	box-shadow: $hoffset1 $voffset1 $blur1 $spread1 $color1 inset, 
		$hoffset2 $voffset2 $blur2 $spread2 $color2 inset,
		$hoffset3 $voffset3 $blur3 $spread3 $color3;
}