@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
@import '../../styles/buttons';
.LandingPage {
	min-height: 100%;
	font-size: 14px;
	background-color: $green;

	.LandingPage-wrapper {
		background-image: linear-gradient(to bottom, $loginBgGradient1, $loginBgGradient2);
		background-size: 100% 45em;
		background-position: center top;

		.LandingPage-header {
			padding-right: 2em;
			padding-left: 2em;
			padding-top: 2.2em;
			@include flex('flex-start', 'center', 'column');

			.LandingPage-subtitle {
				margin-top: 3em;
				width: 33.75em;
				height: 6.1em;
				background-image: url(../../assets/images/logos/logo-platform-subtitle.svg);
			}

			.LandingPage-language {
				position: absolute;
				right: 3.56em;
				top: 2.44em;
			}
		}

		.LandingPage-content {
			@include flex('', 'center', 'column');
			padding-top: 3em;
			height: calc(
				100% -
				3em - 				/* padding top */
				(2.22em + 1em) - 	/* galleryDots */
				1.8em - 			/* Gamelab logo height */
				(6.1em + 3em) - 	/* page subtitle */
				2em 				/* page header top padding */
			);

			.LandingPage-bookDemoButton {
				width: 15.5em;
				min-height: 3.33em;
				height: 3.33em;
				margin-bottom: 3em;
			}

			.LandingPage-galleryWrapper {
				height: calc(22.3em + 7.8em);
				width: 100%;

				.LandingPage-galleryItem {
					position: absolute;
					width: 37.5em;
					left: calc(50% - 37.5em / 2);
					@include flex('', 'center', 'column');
					@include opacity(0);
					
					&.notCurrent {
						@include transition(0.75s);
						@include opacity(0);
					}
					&.isCurrent {
						@include transition(0.75s);
						transition-delay: 0.75s;
						@include opacity(1);
					}

					.LandingPage-galleryTextWrapper {
						width: 37.5em;
						height: 7.8em;
						text-align: center;

						.LandingPage-galleryTextHeader {
							margin-bottom: 1.5em;

							span {
								font-family: 'Metropolis', sans-serif;
								font-size: 1.9em;
								font-weight: bold;
								color: white;
							}
						}
						.LandingPage-galleryText {
							line-height: 1.3em;
							span {
								font-family: 'Metropolis', sans-serif;
								font-size: 1.2em;
								color: white;
							}
						}
					}

					.LandingPage-galleryImage {
						width: 36em;
						height: 22.3em;

						&.computer {
							background-image: url(../../assets/images/landing-page/gallery-computer.svg);
							background-size: 36em 20.1em;
						}
						&.mobile {
							background-image: url(../../assets/images/landing-page/gallery-mobile.svg);
							background-size: 11.2em 22.3em;
							@include rotate(4deg);
						}
						&.tablet {
							background-image: url(../../assets/images/landing-page/gallery-tablet.svg);
							background-size: 27.3em 19.32em;
							@include rotate(1deg);
						}
					}
				}
			}

			.LandingPage-galleryDots {
				width: 5.4em;
				margin-bottom: 1em;
				@include flex('space-between', '', 'row');

				.LandingPage-galleryDot {
					width: 1em;
					height: 1em;
					border-radius: 100%;
					background-color: rgba($blueDark, 0.4);

					&.current {
						background-color: white;
					}
				}
			}

			.LandingPage-arrowLeft,
			.LandingPage-arrowRight {
				position: absolute;
				bottom: calc(100vh - 45em - (4.44em / 2));
				width: 4.44em;
				height: 4.44em;
				border-radius: 100%;
				cursor: pointer;
				@include transition(0.1s, 'transform');
			}
			.LandingPage-arrowLeft {
				left: 2em;
				background-image: url(../../assets/images/landing-page/gallery-arrow.svg);
				&:hover {
					@include scale(1.1);
				}
			}
			.LandingPage-arrowRight {
				right: 2em;
				background-image: url(../../assets/images/landing-page/gallery-arrow.svg);
				@include rotate(180deg);
				&:hover {
					@include rotateAndscale(180deg, 1.1);
				}
			}
		}
	}

	/* Wide portrait (like tablets) */
	&.portrait-wide,
	/* Portrait */
	&.portrait {
		.LandingPage-wrapper {
			.LandingPage-header {
				padding-top: 4.5em;
				
				.LandingPage-subtitle {
					max-width: calc(100% + 1em);
				}

				.LandingPage-language {
					right: 2em;
				}
			}

			.LandingPage-content {
				.LandingPage-galleryWrapper {
					@include flex('', 'center', 'column');
					height: 33em;
					.LandingPage-galleryItem {
						max-width: calc(100% - 2em);
						left: auto;

						.LandingPage-galleryTextWrapper {
							max-width: calc(100% - 2em);
						}

						.LandingPage-galleryImage {
							width: calc(100% - 2em);
							max-width: calc(100% - 2em);

							&.computer {
								background-image: url(../../assets/images/landing-page/gallery-computer.svg);
								background-size: calc(100% - 2em) auto;
							}
							&.mobile {
								background-image: url(../../assets/images/landing-page/gallery-mobile.svg);
								background-size: auto calc(100% - 2em);
								@include rotate(4deg);
							}
							&.tablet {
								margin-top: 2em;
								background-image: url(../../assets/images/landing-page/gallery-tablet.svg);
								background-size: calc(100% - 5em) auto;
								@include rotate(1deg);
							}
						}
					}
				}
			}
		}
	}

	/* Portrait */
	&.portrait {
		.LandingPage-wrapper {
			.LandingPage-content {

				.LandingPage-galleryDots {
					width: 10em;
					margin-bottom: 3em;
					@include flex('space-between', '', 'row');
	
					.LandingPage-galleryDot {
						width: 1.5em;
						height: 1.5em;
					}
				}
				.LandingPage-arrowLeft,
				.LandingPage-arrowRight {
					display: none;
				}
			}
		}
	}

	&.portrait-wide {
		.LandingPage-wrapper {
			.LandingPage-content {
				.LandingPage-arrowLeft,
				.LandingPage-arrowRight {
					bottom: calc(100vh - 45em - (3.33em / 2));
					width: 3.33em;
					height: 3.33em;
					display: block;
				}
				.LandingPage-arrowLeft {
					left: 1em;
				}
				.LandingPage-arrowRight {
					right: 1em;
				}
			}
		}
	}
}